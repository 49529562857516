<template>
  <form @submit.prevent="saveItem">
    <b-card title="Setting App">
      <b-row>
        <b-col cols="12" md="6" v-for="(items, key) in settings" :key="key">
          <div class="form-group">
            <label class="text-capitalize">{{
              items.key.replaceAll("_", " ").toLowerCase()
            }}</label>
            <input
              type="text"
              :ref="items.key"
              v-model="inputSettings[key]"
              class="form-control"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-col cols="12" class="p-0 mt-2">
      <template v-if="isLoading">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
          disabled
        >
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </button>
      </template>
      <template v-else>
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
      </template>
    </b-col>
  </form>
</template>

<script>
import {
  BCard,
  BCardText,
  BForm,
  BButton,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BButton,
    BCol,
    BRow,
    BSpinner,
  },

  data() {
    return {
      settings: [],
      inputSettings: [],
      isLoading: false,
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http.get("/api/v1/admin/settings").then((response) => {
        this.settings = response.data.data;
        this.inputSettings = this.settings.map((e) => {
          return e.value;
        });
        this.isLoading = false;
      });
    },
    saveItem() {
      this.isLoading = true;
      const payload = {};
      for (let index = 0; index < this.settings.length; index++) {
        const element = this.settings[index];
        payload[element.key] = this.$refs[element.key][0].value;
      }

      this.$http
        .post("/api/v1/admin/settings", JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          successNotification(this, "Success", "Settings App Berhasil diedit!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style></style>
